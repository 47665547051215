import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Web-Accessibility hat viele Facetten: Die Bedienung mit der Tastatur und anderen Eingabegeräten,
Alternativtexte für Bilder, verständliche Überschriften und Fehlertexte, usw. Ebenso vielfältig ist das
Angebot an Tools, um die Barrierefreiheit von Websites zu testen.`}</p>
    <p>{`Eines gleich vorweg: Es gibt nicht das `}<ItalicText mdxType="ItalicText">{`eine Power-Tool`}</ItalicText>{`, welches alle Barrieren
automatisch erkennt und am besten auch gleich behebt. Das gilt auch für KI-basierte Accessibility-Tools.
Ja, künstliche Intelligenz hat großes Potential, aber
`}<a parentName="p" {...{
        "href": "https://adrianroselli.com/2023/06/no-ai-will-not-fix-accessibility.html"
      }}>{`aktuelle Tools können keinen umfassenden Barrierefreiheits-Test`}</a>{`
ersetzen. Auch wenn gewisse Leute im Rausch des
`}<a parentName="p" {...{
        "href": "https://www.forbes.com/sites/christianstadler/2024/09/06/the-generative-ai-hype-is-almost-over-whats-next/"
      }}>{`KI-Hypes`}</a>{`
etwas anderes behaupten.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBP/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABpDunlRof/8QAGRAAAgMBAAAAAAAAAAAAAAAAAhEAAQMS/9oACAEBAAEFAh16rTQxGuUlOain/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERIRASMkH/2gAIAQEABj8C5IjZv0VSys//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMVFh/9oACAEBAAE/IdfrwZUdGQuDpZmKv4QmIaEH/9oADAMBAAIAAwAAABDrP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQMBAT8QDtpf/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8Qqv/EABwQAQEAAgIDAAAAAAAAAAAAAAERAFExYSFBgf/aAAgBAQABPxBIX3PPZgCBaSiENmRbB5ZT6wjAJIaxKyn3ADjP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Verschiedene Gartenwerkzeuge und Pflanzen auf einem Tisch.",
          "title": "Verschiedene Gartenwerkzeuge und Pflanzen auf einem Tisch.",
          "src": "/static/7dbd04e7d2902ac8918de4e2a092e3c1/e5166/pexels-thirdman-garden-tools.jpg",
          "srcSet": ["/static/7dbd04e7d2902ac8918de4e2a092e3c1/f93b5/pexels-thirdman-garden-tools.jpg 300w", "/static/7dbd04e7d2902ac8918de4e2a092e3c1/b4294/pexels-thirdman-garden-tools.jpg 600w", "/static/7dbd04e7d2902ac8918de4e2a092e3c1/e5166/pexels-thirdman-garden-tools.jpg 1200w", "/static/7dbd04e7d2902ac8918de4e2a092e3c1/b17f8/pexels-thirdman-garden-tools.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Thirdman / pexels.com`}</em></p>
    <p>{`Damit das zarte Pflänzchen Barrierefreiheit gedeiht, brauchen wir die richtigen Werkzeuge. Hier ist meine
persönliche Auswahl an kostenlosen Bookmarklets, Browser-Erweiterungen und Tools zum Testen von Barrierefreiheit.`}</p>
    <h2>{`Struktur und Semantik`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`WAVE (Web Accessibility Evaluation Tools)`}</a>{`: Ein Barrierefreiheits-Analysetool
von WebAIM. Als Browser-Erweiterung für Firefox, Chrome und Edge verfügbar. Besonders praktisch finde ich
die Struktur-Ansicht, wo man auf einen Blick sieht, ob sinnvolle Landmarks
(`}<InlineCode mdxType="InlineCode">{`header`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`main`}</InlineCode>{` etc.) und Überschriften-Ebenen
definiert wurden.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://firefox-source-docs.mozilla.org/devtools-user/accessibility_inspector/"
        }}>{`Firefox Accessibility Inspector`}</a>{`
ist ein fixer Bestandteil der Firefox-Entwicklertools. Ihr wollt wissen, welche Informationen ein
interaktives Element an assistive Technologien übermittelt? Einfach Rechtsklick darauf
und `}<ItalicText mdxType="ItalicText">{`"Barrierefreiheit-Eigenschaften untersuchen"`}</ItalicText>{` auswählen. Firefox öffnet den
"Barrierefreiheit"-Tab in den Entwicklertools und springt zum zugehörigen Knoten im
`}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Glossary/Accessibility_tree"
        }}>{`Accessiblity-Tree`}</a>{`. Debugging war
noch nie so einfach! 😍`}</li>
      <li parentName="ul">{`Testen mit `}<BoldText mdxType="BoldText">{`Screenreader`}</BoldText>{`: Keine Barrierefreiheits-Prüfung ist komplett ohne
Screenreader-Tests. Angesichts begrenzter Budgets in Projekten, kann man nicht mit
`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/List_of_screen_readers"
        }}>{`allen Screenreadern auf allen Plattformen`}</a>{` testen.
Auf der Basis `}<a parentName="li" {...{
          "href": "https://webaim.org/projects/screenreadersurvey10/"
        }}>{`aktueller Statistiken`}</a>{` habe ich daher
folgende Testszenarien definiert:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.nvaccess.org/"
            }}>{`NVDA`}</a>{` in Windows`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.apple.com/accessibility/vision/"
            }}>{`VoiceOver`}</a>{` in iOS`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://support.google.com/accessibility/android/answer/6283677?hl=de"
            }}>{`TalkBack`}</a>{` in Android`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Falls ihr noch nie mit dem Screenreader getestet habt, dann lest am besten meinen Artikel
`}<a parentName="li" {...{
          "href": "/de/a11y-audits-screenreader.de/"
        }}>{`„5 Tipps für den Einstieg in Barrierefreiheits-Prüfungen mit dem Screenreader“`}</a>{`.`}</li>
    </ul>
    <p>{`Ideal zum Testen der WCAG-Kriterien `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/info-and-relationships.html"
      }}>{`1.3.1 Info und Beziehungen`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/name-role-value.html"
      }}>{`4.1.2 Name, Rolle, Wert`}</a>{`.`}</p>
    <h2>{`Tastaturbedienung`}</h2>
    <ul>
      <li parentName="ul">{`Testen mit `}<BoldText mdxType="BoldText">{`Tastatur`}</BoldText>{`: Mit der Tabulatortaste die interaktiven Elemente der Seite
(Buttons, Links etc.) durchgehen. Ist die Fokus-Reihenfolge sinnvoll? Können Tastatur-Nutzer:innen alle
interaktiven Elemente erreichen und aktivieren? Wird das fokussierte Element sichtbar hervorgehoben?
Am Smartphone oder Tablet könnt ihr einfach eine Bluetooth-Tastatur verbinden.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chromewebstore.google.com/detail/taba11y-tab-order-accessi/aocppmckdocdjkphmofnklcjhdidgmga"
        }}>{`taba11y`}</a>{`:
Diese praktische Browser-Erweiterung berechnet die Tab-Reihenfolge aller interaktiven Elemente und stellt
sie visuell dar, entweder durch Zeichnen eines Pfades oder durch Hervorheben der Elemente.`}</li>
    </ul>
    <p>{`Ideal zum Testen der WCAG-Kriterien `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/keyboard.html"
      }}>{`2.1.1 Tastatur`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/no-keyboard-trap"
      }}>{`2.1.2 Keine Tastaturfalle`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-order"
      }}>{`2.4.3 Fokus-Reihenfolge`}</a>{` und
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-visible"
      }}>{`2.4.7 Fokus sichtbar`}</a>{`.`}</p>
    <h2>{`Responsive Inhalte`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.microsoft.com/en-us/edge/learning-center/how-to-change-font-size?form=MA13I2"
        }}>{`Browser-Einstellungen`}</a>{`:
Ändere die Standardschriftgröße deines Browsers, um zu kontrollieren, ob die Website automatisch die
Textgröße anpasst. Die meisten Browser wie z.B. Edge erlauben nur die Auswahl aus vordefinierten
Optionen wie "Klein", "Mittel" und "Groß". Im `}<a parentName="li" {...{
          "href": "https://support.mozilla.org/en-US/kb/change-fonts-and-colors-websites-use"
        }}>{`Firefox`}</a>{`
können Nutzer:innen hingegen einen konkreten Pixelwert als Standardschriftgröße definieren.`}</li>
      <li parentName="ul">{`Alternativ könnt ihr mit der Zoom-Funktion des Browsers (`}<InlineCode mdxType="InlineCode">{`STRG`}</InlineCode>{`
und `}<InlineCode mdxType="InlineCode">{`+`}</InlineCode>{`-Taste) den gesamten Seiteninhalt proportional zur Schriftgröße vergrößern.`}</li>
      <li parentName="ul">{`Benutze das `}<a parentName="li" {...{
          "href": "https://codepen.io/stevef/full/YLMqbo"
        }}>{`Textabstände-Bookmarklet`}</a>{` oder eine Browser-Erweiterung
(z.B. `}<a parentName="li" {...{
          "href": "https://chromewebstore.google.com/detail/editor-for-textlayout/amnelgbfbdlfjeaobejkfmjjnmeddaoj"
        }}>{`Editor for Textlayout`}</a>{`),
um die Werte für Zeilenhöhe, Abstand nach Absätzen, Buchstabenabstand und Wortabstand auf die maximal
geforderten Werte zu setzen. Es sollten keine Inhalte abgeschnitten werden oder einander überlappen.`}</li>
    </ul>
    <p>{`Ideal zum Testen der WCAG-Kriterien `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/resize-text.html"
      }}>{`1.4.4 Textgröße ändern`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/reflow"
      }}>{`1.4.10 Automatischer Umbruch (Reflow)`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/text-spacing"
      }}>{`1.4.12 Textabstand`}</a>{`.`}</p>
    <h2>{`Zielgröße von interaktiven Elementen`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://html5accessibility.com/stuff/2023/08/28/quick-and-very-dirty-target-size-checker/"
        }}>{`Target Size Bookmarklet`}</a>{`:
Es identifiziert interaktive Elemente, errechnet deren Mittelpunkt und zeichnet einen halbtransparenten
Kreis von 24 mal 24 Pixeln um den Mittelpunkt des Elements: Ein grüner Kreis mit dickem Rand bedeutet, dass
die Zielgröße ausreicht. Elemente mit blauem Kreis sollten manuell untersucht werden.`}</li>
      <li parentName="ul">{`Auf diese Weise findet ihr viel schneller Buttons und Links, bei denen Nutzer:innen mit motorischer
Beeinträchtigung Probleme beim Anklicken oder Antippen haben könnten.`}</li>
    </ul>
    <p>{`Ideal zum Testen des WCAG-Kriteriums `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum"
      }}>{`2.5.8 Zielgröße (Minimum)`}</a>{`.`}</p>
    <h2>{`Mindestkontrast`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.chrome.com/docs/lighthouse/overview"
        }}>{`Chrome Lighthouse`}</a>{` ist Teil der Entwickler-Tools
in Chrome. Der Accessibility-Check von Lighthouse findet zuverlässig einen Großteil der Elemente mit zu
geringem Kontrastverhältnis.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://learn.microsoft.com/de-de/windows/powertoys/#color-picker"
        }}>{`PowerToys Farbwähler`}</a>{`:
Die Microsoft PowerToys sind eine Sammlung von Hilfsprogrammen für Poweruser. Mit der
Tastenkombination `}<InlineCode mdxType="InlineCode">{`WIN`}</InlineCode>{`+`}<InlineCode mdxType="InlineCode">{`Umschalt`}</InlineCode>{`+`}<InlineCode mdxType="InlineCode">{`C`}</InlineCode>{`
könnt ihr jederzeit den Farbwähler aktivieren und den Farbwert eines Pixels am Bildschirm auslesen.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/resources/contrastchecker/"
        }}>{`WebAIM Contrast Checker`}</a>{`: Einfaches Tool, um das
Kontrastverhältnis einer Schriftfarbe zur Hintergrundfarbe zu prüfen. Besonders praktisch ist das Feature,
dass man die gewählten Farbwerte inklusive Auswertung als Permalink teilen kann.`}</li>
    </ul>
    <p>{`Ideal zum Testen der WCAG-Kriterien `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum.html"
      }}>{`1.4.3 Kontrast (Minimum)`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/non-text-contrast"
      }}>{`1.4.11 Nicht-Text-Kontrast`}</a>{`.`}</p>
    <h2>{`Weitere Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/test-evaluate/tools/list/"
        }}>{`Web Accessibility Evaluation Tools List  (W3C)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibility.huit.harvard.edu/testing"
        }}>{`Accessibility Testing Tools and Practices (Harvard University)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      